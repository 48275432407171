require("trix");
require("@rails/actiontext");

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

import 'trix/dist/trix.css';
import 'bootstrap';

import 'imask';
import 'ace-builds/src-noconflict/ace';
import 'ace-builds/webpack-resolver';

import 'lightbox2/dist/js/lightbox-plus-jquery';
import 'lightbox2/dist/css/lightbox.css';

import 'justifiedGallery/dist/js/jquery.justifiedGallery';
import 'justifiedGallery/dist/css/justifiedGallery.css';

import "@fortawesome/fontawesome-free/css/all.css";
// import "@fortawesome/fontawesome-free/js/all";

import 'select2/dist/js/select2';
import 'select2/dist/css/select2.css';

import "suggestions-jquery/dist/js/jquery.suggestions";
import 'suggestions-jquery/dist/css/suggestions.min.css';

import "fotorama/fotorama.js";
import "fotorama/fotorama.css";

import './src/application.scss';
import './src/tristate_select.scss';

import "./custom/custom.js";
import "./custom/collapse.js";
import "./custom/add_to_cart.js";
import "./custom/alert.js";
import "./custom/tristate_select.js";
import "./custom/resize_scroll.js";

import "pusher-js";